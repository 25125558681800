<template>
  <div class="page-wrap">
    <div class="main-page-column">
      <div class="page--not-found text-center">
        <h1 class="pb-5">Not Allowed</h1>
        <p>Is seems you are not allowed to access this app or page</p>
        <p>
          <v-btn color="primary" @click="back">
            Back to safety
          </v-btn>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotAllowed',
  methods: {
    back() {
      return this.$router.replace('/')
    }
  }
}
</script>

<style>
.page--not-found {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 150px);
}
</style>
